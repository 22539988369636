@import 'scss/variables/_commonColors.scss';

$primary: #2061A9;
$primary-hover: $primary;
$secondary: #4BBDBF;
$light-secondary: #ECF8F8;
$secondary-hover: #02BE00;
$flat-secondary-hover: rgba(2, 190, 0, 0.08);
$tertiary: #1D558C;
$tertiary-hover: #184673;
$flat-tertiary-hover: rgba(24, 70, 115, 0.08);
$light-primary: #33BDC0;
$light-primary-hover: #2FACAF;
$flat-light-primary-hover: rgba(41, 89, 147, 0.08);
$error-red: #F44336;
$gray-light: #F7F7F9;
$gray-border: #EFEFEF;
$gray-disabled-day: #F3F3F3;
$gray-disabled-day-boder: $gray-border;
$green: #79C654;
$border-logo-sign-in: $primary;
$confirmed-turn: $green;
$cancelled-turn: $error;
$pending-turn: #F3CF60;

$color-map: (
  ut-topbar: (
    background: $primary,
    border: $gray-2,
    disabled-base: $gray-3,
    shadow: transparent
  ),
  dashboard: (
    footer: (
      background: $primary,
      shadow: $gray
    )
  ),
  manage-turns: (
    selection: $secondary,
    filter-background: $secondary,
    filter-hover: darken($secondary, 20%),
    button-color: $secondary,
    search-button-background: $green,
    search-button-hover: darken($green, 20%)
  )
);

// sass-lint:disable no-misspelled-properties
// sass-lint:disable property-sort-order
:export {
  black: $black;
  white: $white;
  gray: $gray;
  gray2: $gray-2;
  grayLight2: $gray-light-2;
  disabledGray: $gray-2;
  errorRed: $error-red;
  primary: $primary;
  secondary: $secondary;
  tertiary: $tertiary;
  lightPrimary: $light-primary;
  lightPrimaryHover: $light-primary-hover;
  flatLightPrimaryHover: $flat-light-primary-hover;
  tertiaryHover: $tertiary-hover;
  flatTertiaryHover: $flat-tertiary-hover;
  secondaryHover: $secondary-hover;
  flatSecondaryHover: $flat-secondary-hover;
  loadingBase: $primary;
}
